<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:45:02
-->
<template>
	<div class="createMaterials">
		<div class="createMaterials-content">
			<div class="common-row">
				<div class="common-row-item common-row-own">
					<span class="common-row-label">材料收集</span>
					<el-input class="common-row-ipt" placeholder="请输入材料收集标题名称" v-model="createMaterialsData.name" />
				</div>
			</div>
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">组织名称</span>
					<el-select multiple class="common-row-select" v-model="createMaterialsData.organizationIds"
						placeholder="请选择组织（可多选）">
						<el-checkbox style="padding-left: 20px;" @change="selectAllOrg" v-model="allOrgChecked">全 部</el-checkbox>
						<el-option v-for="item in originList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="common-row">
				<div class="common-row-item common-row-own">
					<span class="common-row-label">截止时间</span>
					<!-- <el-date-picker value-format="yyyy-MM-dd" class="common-row-ipt" v-model="createMaterialsData.deadline" type="date"
						placeholder="请选择材料收集截止时间">
					</el-date-picker> -->
					<el-date-picker format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" class="common-row-ipt"
						v-model="createMaterialsData.deadline" type="datetime" placeholder="请选择材料收集截止时间">
					</el-date-picker>
				</div>
			</div>
			<div class="common-row" style="margin-top: 20px;">
				<el-button v-preventReClick @click="submit" type="primary">确定新建</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getDepartmentList,
	} from "@/api/system";
	import {
		addMaterials,
	} from "@/api/materials";
	export default {
		data() {
			return {
				createMaterialsData: {
					name: "",
					deadline: undefined,
					organizationIds: [],
				},
				originList: [],
			};
		},
		computed: {
			allOrgChecked: {
				get() {
					let orgIds = this.originList.map(item => item.value)
					// console.log("orgIds", this.createMaterialsData.organizationIds)
					if(orgIds.length > 0) {
						if(this.createMaterialsData.organizationIds.length == orgIds.length) {
							return true
						} else {
							return false
						}
					} else {
						return false
					}
				},
				set(newVal) {
					return newVal
				},
				// this.originList
			},
		},
		mounted() {
			this.departmentList()
		},
		methods: {
			selectAllOrg(e) {
				console.log("selectAllOrg", e)
				let orgIds = this.originList.map(item => item.value)
				if(e) {
					this.createMaterialsData.organizationIds = orgIds
				} else {
					this.createMaterialsData.organizationIds = []
				}
			},
			// 获取分会列表
			departmentList() {
				let params = {
					pageIndex: 1,
					pageSize: 999,
					type: "SUB_COMP"
				}
				this.originList = [];
				getDepartmentList(params).then((res) => {
					if (res.code == 0) {
						this.originList = res.data.map(item => {
							return {
								label: item.name,
								value: item.id
							}
						})
					}
				})
			},
			submit() {
				let submitData = JSON.parse(JSON.stringify(this.createMaterialsData))
				if (!submitData.name) {
					this.$message.error("请输入材料收集标题名称~")
					return
				}
				if (!submitData.organizationIds || submitData.organizationIds.length == 0) {
					this.$message.error("请选择组织（可多选）~")
					return
				}
				if (!submitData.deadline) {
					this.$message.error("请选择材料收集截止时间~")
					return
				}
				addMaterials(submitData).then((res) => {
					if (res.code == 0) {
						this.$message.success("新建材料收集成功~")
						this.createMaterialsData = {
							name: "",
							deadline: undefined,
							organizationIds: [],
						}
					}
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.common-row-own .el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	.createMaterials {
		.createMaterials-content {
			padding: 20px 20px;

			.common-row {
				margin-bottom: 16px;

				.common-row-ipt {
					width: 100%;
					height: 36px;
					line-height: 36px;
					border: 1px solid #EDF1F7;
					border-left: 0;
				}

				.common-row-select {
					width: 100%;
					border: 1px solid #EDF1F7;
					border-left: 0;
				}
			}

		}
	}
</style>
